<template>
  <v-layout fill-height column align-center justify-center>
      <div class="d-flex text-h2 my-0 grey--text text--lighten-2">
        <v-slide-x-transition>
          <div>Hello, {{ currentUser.name }}!</div>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <v-progress-circular v-if="userInfoLoading" size="48" indeterminate color="grey lighten-2" value="true"></v-progress-circular>
        </v-slide-x-transition>
      </div>
  </v-layout>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Dashboard",
  computed: {
    ...mapState('user', {
      currentUser: 'current',
      userInfoLoading: 'loading'
    }),
  }
}
</script>